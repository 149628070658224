import React from 'react'
import './portfolio.css'
import IMG1 from '../../assets/mergim-rexha-portfolio-apply-high.jpg'
import IMG2 from '../../assets/mergim-rexha-portfolio-alles-im-leben-hat-einen-grund.jpg'
import IMG3 from '../../assets/mergim-rexha-portfolio-rexhawood.jpg'
import IMG4 from '../../assets/mergim-rexha-portfolio-meiddeway.jpg'
import IMG5 from '../../assets/mergim-rexha-portfolio-text-counter.jpg'
import IMG6 from '../../assets/mergim-rexha-portfolio-mergimatik.jpg'
import IMG7 from '../../assets/mergim-rexha-portfolio-mbox-splitter.jpg'
import IMG8 from '../../assets/mergim-rexha-portfolio-cleanita.jpg'

// DO NOT USE THE IMAGES IN PRODUCTION

const data = [
  {
    id: 1,
    image: IMG8,
    title: 'Cleanita',
    description: 'Cleanita ist ein innovatives mobiles Bidet, das darauf abzielt, die persönliche Hygiene zu verbessern. Durch seine kompakte und tragbare Bauweise ermöglicht es eine bequeme Reinigung, ohne auf Wasseranschlüsse oder elektrische Energie angewiesen zu sein. Mit nur einer Füllung kannst du Cleanita mehrfach nutzen, was nicht nur praktisch, sondern auch umweltfreundlich ist, da es den Verbrauch von Toilettenpapier reduziert. Die aufladbare Wasserpumpe sorgt für einen kontrollierten Wasserfluss, und das intuitive Design macht die Anwendung einfach und effektiv. Cleanita ist die moderne Lösung für eine hygienische und nachhaltige Toilettenroutine.',
    link: 'https://cleanita.de',
    buttontext: 'Zur Webseite'
  },
  {
    id: 2,
    image: IMG5,
    title: 'Text-Counter',
    description: 'Oftmals möchte man in einem Text bestimmte Dinge zählen, wie z. B. Wörter oder Zeichen. Es wäre eine Zumutung, das manuell zu machen. Text-Counter.com ist eine Webseite, die alles Erdenkliche in einem Text zählt. Wörter, Leerzeichen, Ausrufezeichen, Hashtag, Kommas und viele weitere Inhalte werden gezählt und dargestellt. Hierbei hat der Nutzer ein Textfeld, indem er seinen Text einfügen kann. Unter dem Input Feld befinden sich alle Zähler, die aktiviert werden, sobald sie etwas zählen, worauf sie programmiert sind. ',
    link: 'https://text-counter.com',
    buttontext: 'Zur Webseite'
  },
  {
    id: 3,
    image: IMG7,
    title: 'Mbox-Splitter',
    description: 'Durch einen Domainumzug von einem Anbieter zum anderen, müssen ebenfalls die E-Mail-Adressen umgezogen werden. Hierbei werden die E-Mails in einer sogenannten .mbox Datei exportiert. Die Datei kann mehrere 100 MB enthalten. Beim Importieren der .mbox Datei beschränken die Hosting Anbieter teilweise die Größe der Datei. Der Online mbox-splitter den ich entwickelt habe, ermöglicht es die Datei hochzuladen und eine maximale Größe anzugeben. Die Datei wird dann in mehreren Einzeldateien gesplittet, sodass Sie vom Hostinganbieter zugelassen werden.',
    link: 'https://mbox-splitter.com',
    buttontext: 'Zur Webseite'
  },
  {
    id: 4,
    image: IMG1,
    title: 'ApplyHigh',
    description: 'Gemeinsam mit drei Studienkommilitonen haben wir zwei Jahre eine Plattform vollständig eigenentwickelt, um einzigartige und gut aussehende Bewerbungen, ohne Vorkenntnisse, innerhalb von 10 Minuten schnell und einfach gestalten zu können. Ziel war es, eine Möglichkeit zu schaffen eine tolle Bewerbung zu generieren, ohne jegliche Kenntnisse im Design zu besitzen. Hierbei werden die Daten vom Kunden über ein Formular eingegeben und den Rest macht die Plattform vollständig automatisiert.',
    link: 'https://apply-high.de',
    buttontext: 'Zur Webseite'
  },
  {
    id: 5,
    image: IMG2,
    title: 'Alles im Leben hat einen Grund',
    description: 'In meinem Leben gab es viele Höhen und Tiefen. Die Flucht meiner Eltern, meine Kindheit im Asylantenheim, menschliche Beziehungen und die vielen Steine auf dem Weg zum Erfolg. Eine stressvolle Existenzgründung, der Gewinn und Verlust von Freunden und das Streben nach Erfolg. In meinem Buch erzähle ich private und nahegehende Erlebnisse und die Geschichte meiner Eltern. Ziel war es, etwas für die nächsten Generationen in der Familie zu hinterlassen und Fragen im Buch zu beantworten. ',
    link: 'mailto:kontakt@mergim-rexha.de?subject=Alles%20im%20Leben%20hat%20einen%20Grund:Anfrage',
    buttontext: 'Kontaktieren'
  },
  {
    id: 6,
    image: IMG3,
    title: 'RexhaWood',
    description: 'Durch das regelmäßige beobachten von Trends, ist mir damals ein Produkt eingefallen, welches wir gemeinsam mit meinem Vater produziert haben. Hauseigene Produktion gestartet, Firma gegründet und einen Onlineshop programmiert. Marketing betrieben und bis heute meinem Vater einen netten Nebenverdienst ermöglicht, mit mittlerweile knapp 10 Produkten.',
    link: 'https://rexhawood.de',
    buttontext: 'Zur Webseite'
  },
  {
    id: 7,
    image: IMG4,
    title: 'MeidDeway',
    description: 'Im Studium habe ich die Idee gehabt, eine eigene Uhrenmarke zu entwickeln und zu vertreiben. Skizzen angelegt, Uhren entworfen, Produzenten angeschrieben, investiert, Onlineshop programmiert und Marketing betrieben. Die Logistik wurde so weit automatisiert, wie es mir möglich war. Es begann mit zwei Produkten. Zum Schluss beliefen sich die Produkte auf 20 Stück. Durch die geschickte Vermarktung meines Produktes und kluge Entscheidungen konnte ich mein Studium finanzieren.',
    link: 'https://meiddeway.de',
    buttontext: 'Zur Webseite'
  },
  {
    id: 8,
    image: IMG6,
    title: 'Mergimatik',
    description: 'Nur 30 % der Studierenden in meiner Hochschule in der Fachrichtung Informatik waren nach dem zweiten Semester noch eingeschrieben. Das lag in den meisten Fällen an Mathematik 1, 2 oder 3.  Mir ist dabei aufgefallen, dass es an den Basics der Mathematik lag, weshalb ich eine Nachhilfe Plattform für alle Neuankömmlinge im Studium kreiert habe, mit rund 60 Videos.',
    link: 'https://mergimatik.net',
    buttontext: 'Zur Webseite'
  }
]


const Portfolio = () => {
  return (
    <section id='portfolio'>
      <h5>Meine Arbeiten</h5>
      <h2>Portfolio</h2>
      <p className="sub-headline-portfolio">Neben meinem Hauptberuf habe ich bereits früh begonnen, eigene Projekte umzusetzen.<br /> Ziel ist es immer sich auf dem Laufenden zu halten, sich selbstständig weiterzuentwickeln und das Wissen in die Haupttätigkeit einzusetzen. </p>

      <div className="container portfolio__container">
        {
          data.map(({id, image, title, description, link, buttontext}) => {
            return (
              <article key={id} className='portfolio__item'>
              <div className="portfolio__item-image">
                <img src={image} alt={title} />
              </div>
              <h3>{title}</h3>
              <p>{description}</p>
              <div className="portfolio__item-cta">
                <a href={link} className='btn'>{buttontext}</a>
              </div>
            </article>
            )
          })
        }
      </div>
    </section>
  )
}

export default Portfolio